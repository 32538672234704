import React from 'react';
import { motion } from "framer-motion";
const LightIcon = () => {

const pathVariant = {
  initial: {
    pathLength: 0,
  },
  night: {
    pathLength: 1,
    fill:"none",
    transition: {
      duration: 1,
      ease: "easeInOut",
    }
  },
}
  return ( 
    <motion.svg xmlns="http://www.w3.org/2000/svg" className="ionicon" viewBox="0 0 512 512"
      whileTap={{scale: 0.9, originX: 0, originY: 0}}
    >
    <motion.path d="M90.61 306.85A16.07 16.07 0 00104 293.6C116.09 220.17 169.63 176 232 176c57.93 0 96.62 37.75 112.2 77.74a15.84 15.84 0 0012.2 9.87c50 8.15 91.6 41.54 91.6 99.59 0 59.4-48.6 100.8-108 100.8H106c-49.5 0-90-24.7-90-79.2 0-48.47 38.67-72.22 74.61-77.95z" stroke="#000" fill="none"
    variants={pathVariant}
    initial="initial"
    animate="night"
    />
    <motion.path d="M384.8 271.4a80 80 0 10-123.55-92M464 208h32M336 48v32M222.86 94.86l22.63 22.63M449.14 94.86l-22.63 22.63" stroke="#000" fill="none"
    variants={pathVariant}
    initial="initial"
    animate="night"
    />
    </motion.svg>
   );
}
 
export default LightIcon;