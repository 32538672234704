import React from 'react';
import { motion } from "framer-motion";

const pathVariant = {
  initial: {
    pathLength: 0,
  },
  night: {
    pathLength: 1,
    fill:"none",
    transition: {
      duration: 1,
      ease: "easeInOut",
    }
  },
}

const NightIcon = () => {
  return ( 
    <motion.svg xmlns="http://www.w3.org/2000/svg" className="ionicon" viewBox="0 0 512 512"
      whileTap={{scale: 0.9, originX: 0, originY: 0}}
    >
      <motion.path d="M388.31 272c47.75 0 89.77-27.77 107.69-68.92-14.21 6.18-30.9 8.61-47.38 8.61A116.31 116.31 0 01332.31 95.38c0-16.48 2.43-33.17 8.61-47.38C299.77 65.92 272 107.94 272 155.69a116.31 116.31 0 003.44 28.18" stroke="#fff" fill="none"
      variants={pathVariant}
      initial="initial"
      animate="night"
      />
      <motion.path d="M90.61 306.85A16.07 16.07 0 00104 293.6C116.09 220.17 169.63 176 232 176c57.93 0 96.62 37.75 112.2 77.74a15.84 15.84 0 0012.2 9.87c50 8.15 91.6 41.54 91.6 99.59 0 59.4-48.6 100.8-108 100.8H106c-49.5 0-90-24.7-90-79.2 0-48.47 38.67-72.22 74.61-77.95z"
      stroke="#fff" fill="none"
      variants={pathVariant}
      initial="initial"
      animate="night"
      />
    </motion.svg>
   );
}
 
export default NightIcon;