export const projectData = [
  {
    live_link: "https://navigate-jade.vercel.app/",
    git_link: "https://github.com/kasboi/navigate",
    project_name: "Navigate - Urban Areas",
    project_desc: "An open source project for discovering quality of life and average salaries for various job sectors in urban areas. Includes search, filter, pictures, score of living, infinite scroll amongst other features.",
    language: ["Typescript", "Next.js","React","Teleport Open API"]
  },
  {
    live_link: "https://vencru-two.vercel.app/",
    git_link: "https://github.com/kasboi/vencru",
    project_name: "Responsive Dashboard",
    project_desc: "A simple responsive dashboard design I implemented as part of a take home project",
    language: ["Typescript", "Next.js","React","Tailwind"]
  },
  {
    live_link: "https://leafy-tapioca-afb2d9.netlify.app/",
    git_link: "#",
    project_name: "Bootcamp Portfolio",
    project_desc: "A portfolio made with HTML5 and CSS3 that displays my student projects and skills to recruiters. The portfolio is simple, responsive and looks nice. We hope it shows that they are capable and ready to work with a team or organization.",
    language: ["HTML","CSS","CSS Grid"] 
  },
  {
    live_link: "https://careerconnect-bootcamp-page.netlify.app/",
    git_link: "https://github.com/kasboi/hotel-landing-page",
    project_name: "Hotel Landing Page",
    project_desc: "We created this landing page using CSS flexbox and stunning animations as part of the frontend boot camp program. My goal was to impart practical knowledge of various CSS topics.",
    language: ["HTML","CSS","Flexbox", "Animations"] 
  },
  {
    live_link: "https://ts-weather-forecast.netlify.app/",
    git_link: "https://github.com/kasboi/React-Weather-App-Typescript#",
    project_name: "Weather Forecast",
    project_desc: "A 5-day weather forecast web app. This project Consumes 2 APIs [GeoDB & Openweathermap] to accurately determine the latitude and longitude of cities and their weather report for the next 5 days.",
    language: ["Typescript","React","API"]
  },
  {
    live_link: "https://kas-notesapp.netlify.app/",
    git_link: "https://github.com/kasboi/notes_app",
    project_name: "Note Keeper",
    project_desc: "Fullstack web app for note taking and tracking. Equiped with Firebase services (authentication & firestore).",
    language: ["Javascript","React","Firebase", "Material UI"]
  },
  {
    live_link: "https://codepen.io/kolawole-abdullah-solahudeen/pen/BaJzjoN",
    git_link: "#",
    project_name: "Tic-Tac Toe",
    project_desc: "A beautiful and simple re-creation of the popular 3x3 tic-tac toe game.",
    language: ["Javascript","HTML","CSS"]
  },
  {
    live_link: "https://monster-btc.netlify.app/",
    git_link: "https://github.com/kasboi/monster",
    project_name: "Monster Finance",
    project_desc: "A responsive landing page demo for a blockchain project.",
    language: ["Javascript","HTML","CSS"]
  },
  {
    live_link: "https://asalytics-test-six.vercel.app/",
    git_link: "https://github.com/kasboi/asalytics-test",
    project_name: "Analytics",
    project_desc: "Web application for keeping track of availablity of crypto coins built on top of the Next.js framework + Typescript.",
    language: ["Typescipt","React","Next.js", "Jest"]
  },
  {
    live_link: "https://guitarclub.netlify.app/",
    git_link: "https://github.com/kasboi/guitar",
    project_name: "Guitar Club",
    project_desc: "A small responsive website i made early on while fiddling with SCSS.",
    language: ["Javascript","HTML","SCSS"]
  },
  {
    live_link: "https://ecstatic-volhard-ea4bc9.netlify.app/",
    git_link: "#",
    project_name: "Ace Blog",
    project_desc: "A simple blog that consumes API and uses it to display various news articles.",
    language: ["Javascript","HTML","CSS"]
  },
  {
    live_link: "https://codepen.io/kolawole-abdullah-solahudeen/pen/dyJXGGa/",
    git_link: "#",
    project_name: "Calculator",
    project_desc: "Simple calculator app.",
    language: ["Javascript","HTML","CSS"]
  },
]